import * as React from "react"

const SupportPage = () => {

return (
    <main>
        <html lang="en" id="facebook" class>
            <head>
                <title id="pageTitle">Etouch | Contáctanos
                </title>
            </head>
            <body class="webkit win x1 Locale_es_LA _19_u" dir="ltr">
                <div class="_li">
                    <div class="_9l9u _li">
                        <div class="_7dwk" id="u_0_1y">
                            <iframe frameborder="0" scrolling="no" class="_rp24" src="https://www.oculus.com/tealium/?env=prod" title="9l9u"></iframe>
                        </div>
                        <div class="_4367 _8orj _9bu8">
                            <div class="_9iup">
                                <section class="_4gsw _7og" id="u_0_1z">
                                    <div class="_7p2">
                                    </div>
                                    <div class="_44mf">
                                        <section colorscheme="dark" class="_4gsw _7og">
                                            <div class="_7p2">
                                            </div>
                                        </section>
                                    </div>
                                </section>
                            </div>
                            <div class="_9i79">
                                <div class="_9i7a _OuiPlutoNavigationBar__cfOpaque _9e6k _9ec1 _9ech _9ecd _9ece _9eb_ _9i7c _9i7d" data-testid="navbar-primary" id="u_0_f">
                                    <div class="_9ebw">
                                        <div class="_3bdh _2zis _9e99">
                                            <div class="_1bto _1bts">
                                                <div class="_31m4 _9epc _9ey-">
                                                </div>
                                            </div>
                                            <div class="_1bto _1btp _1btq _7ujy">
                                                <div class="_31m4 _9epc _31n9">
                                                </div>
                                            </div>
                                            <div class="_1bto _1bts _1btp _1btq">
                                                <div>
                                                    <a class="_2v0- _2v12" href="/" id="u_0_g">
                                                        <div class="_9ebx" id="u_0_h">
                                                            <img class="_9eby img" aria-label="Oculus" src={`../LuM5_oJjZsU.svg`} alt=""/>
                                                            <img class="_9ebz img" src={`../X177feMMdb4.svg`} alt=""/>
                                                            <img class="_9eb- img" src={`../dEwO7QAdmX5.svg`} alt=""/>
                                                        </div>
                                                    </a>
                                                    <a class="_2v0- _2v12 _8loh" href="/">
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="_1bto _1btp _1btq _7ujz">
                                                <div id="u_0_i">
                                                    <div class="_vzy _1p0c" tabindex="0" role="button">
                                                        <i class="menu _3n44 _3n45 _3n46 _3n47" colorscheme="light">
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="_9e7q _9e79 _9ec1 _9e7k">
                                                <span class="_9h5p">
                                                    <a class="_9e7d _9e7e _9e7g" data-testid="navlink-productos" href="https://api.whatsapp.com/send?phone=+51957392216&text=Hola%2C%20deseo%20más%20información%20sobre%20Realidad%20Virtual" id="u_0_k">CHATEA CON NOSOTROS
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="_rp1">
                                </div>
                            </div>
                            <div>
                                <div class="_8ky- _1xcn" data-testid="oui_cms_page" id="compare">
                                    <div class="_8ky- _1xcn" data-testid="oui_cms_page">
                                        <div>
                                            <div>
                                                <section class="_4gsw _10ls">
                                                    <div class="_7p2">
                                                        <div class="_1g5f _1g5k _1g5g compare-headsets__hero-copy">
                                                            <div class="_2h23 _1020 _3y6o _1if- _rp17">
                                                                <h1 class="_9d4r _9d4u _9d4w compare-headsets__hero-header _rp3">CONTÁCTANOS 957 392 216
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                        <div class="_8ky- _1xcn" data-testid="oui_cms_page">
                                            <section class="_4gsw _7of _7p0 _7u9r _7u9q">
                                                <div class="_7p2">
                                                    <div class="_7vvj">
                                                        <div class="_7vvk">
                                                            <img class="_7vvl img" src={`../58003282_650889158697285_9201785342246518784_n.png`} alt="Free Shipping"/>
                                                            <h4 class="_9d4r _9d4t _9d4z _7vvm _rp3">Envío gratuito
                                                            </h4>
                                                            <p class="_9d3p _9d3r _9d3u _9d4g _7vvn">Dentro de Lima Metropolitana.
                                                            </p>
                                                        </div>
                                                        <div class="_7vvk">
                                                            <img class="_7vvl img" src={`../57923519_288884555355769_1158349780628275200_n.png`} alt="Oculus Warranty"/>
                                                            <h4 class="_9d4r _9d4t _9d4z _7vvm _rp3">Garantía de Oculus</h4><p class="_9d3p _9d3r _9d3u _9d4g _7vvn">Cobertura de 6 meses por defectos y mal funcionamiento.</p></div></div></div></section></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="_9l0f">
                    <div class="_9e1c" data-testid="footer-copyright">
                        <div class="_9e1h">
                            <div class="_9e1j">
                                <p class="_9d3p _9d3r _9d3u _9d3y _9d4f _9e1k">
                                    <a class="_9e1g" href="/" id="u_0_1w">Productos
                                    </a>
                                </p>
                                
                            </div>
                            <p class="_9d3p _9d3r _9d3u _9d3y _9d4f _9e1i">©&nbsp; Etouch Store, Inc.
                            </p>
                        </div>
                    </div>
                </div>
            </body>  
        </html>
    </main>
)
}

export default SupportPage